.mpc-MenuContent-module-_Gc7qq-root {
  z-index: var(--zIndexPopup);
  background-color: rgb(var(--mpc-color-white-bg));
  box-shadow: var(--boxShadowPopup);
  transition: var(--transitionStyleButton);
  border-radius: 0 0 2px 2px;
  animation-name: mpc-MenuContent-module-_Gc7qq-slideInUp;
  animation-duration: .2s;
  animation-fill-mode: both;
  position: absolute;
}

@media (min-width: 640px) {
  .mpc-MenuContent-module-_Gc7qq-root {
    animation-name: none;
  }
}

@keyframes mpc-MenuContent-module-_Gc7qq-slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.mpc-MenuContent-module-_Gc7qq-isClosed {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  display: none;
}

@media (min-width: 640px) {
  .mpc-MenuContent-module-_Gc7qq-isClosed {
    display: block;
  }
}

.mpc-MenuContent-module-_Gc7qq-isOpen {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  display: block;
}

.mpc-MenuContent-module-_Gc7qq-content {
  flex-direction: column;
  margin: 0;
  display: flex;
}

.mpc-MenuContent-module-_Gc7qq-arrowTop, .mpc-MenuContent-module-_Gc7qq-arrowBelow {
  content: " ";
  pointer-events: none;
  border: solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  position: absolute;
  bottom: 100%;
}

.mpc-MenuContent-module-_Gc7qq-arrowTop {
  border-bottom-color: rgb(var(--mpc-color-white));
  border-width: 7px;
  margin-left: -7px;
}

.mpc-MenuContent-module-_Gc7qq-arrowBelow {
  border-bottom-color: var(--matterColorNegative);
  border-width: 9px;
  margin-left: -9px;
}

.mpc-MenuLabel-module-xSfBVa-root {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  width: 100%;
  height: 100%;
}

.mpc-MenuLabel-module-xSfBVa-root:hover {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
}

.mpc-MenuLabel-module-xSfBVa-clickedWithMouse:focus {
  outline: none;
}

.mpc-Menu-module-yUKM5G-root {
  outline: none;
  width: auto;
  height: 100%;
  position: relative;
}

.mpc-TopbarSearchForm-module-fkXA4G-mobileInputRoot {
  width: 100%;
}

.mpc-TopbarSearchForm-module-fkXA4G-desktopInputRoot {
  border: .375rem;
  height: auto;
}

.mpc-TopbarSearchForm-module-fkXA4G-mobileIcon {
  border-bottom-width: var(--borderRadiusMobileSearch);
  background-color: rgb(var(--mpc-color-white-bg));
  align-self: stretch;
  width: 24px;
  margin-left: 24px;
  padding: 11px 0 17px;
  display: flex;
}

.mpc-TopbarSearchForm-module-fkXA4G-mobileInput {
  height: var(--TopbarSearchForm_inputHeight);
  line-height: unset;
  border-bottom-width: var(--borderRadiusMobileSearch);
  flex-grow: 1;
  margin: 0 24px 0 0;
  padding: 1px 13px 13px;
}

.mpc-TopbarSearchForm-module-fkXA4G-mobileInput::-webkit-input-placeholder {
  line-height: normal;
}

.mpc-TopbarSearchForm-module-fkXA4G-mobileInput:hover, .mpc-TopbarSearchForm-module-fkXA4G-mobileInput:focus {
  border-bottom-color: var(--marketplaceColor);
  outline: none;
}

.mpc-TopbarSearchForm-module-fkXA4G-desktopIcon {
  background-color: rgb(var(--mpc-color-white-bg));
  border: none;
  align-self: stretch;
  width: 18px;
  height: 44px;
  padding-top: 3px;
  display: flex;
}

.mpc-TopbarSearchForm-module-fkXA4G-bgBlur {
  -webkit-backdrop-filter: saturate(200%) blur(20px);
  backdrop-filter: saturate(200%) blur(20px);
  background: none !important;
}

.mpc-TopbarSearchForm-module-fkXA4G-desktopInput {
  color: currrentColor;
  line-height: unset;
  text-overflow: ellipsis;
  border: none;
  flex-grow: 1;
  height: 44px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;
  overflow-x: hidden;
}

.mpc-TopbarSearchForm-module-fkXA4G-desktopInput::-webkit-input-placeholder {
  line-height: normal;
}

.mpc-TopbarSearchForm-module-fkXA4G-desktopInput:hover, .mpc-TopbarSearchForm-module-fkXA4G-desktopInput:focus {
  border-bottom-color: var(--marketplaceColor);
  outline: none;
}

.mpc-TopbarSearchForm-module-fkXA4G-desktopInput:hover::placeholder {
  color: var(--matterColor);
}

.mpc-TopbarSearchForm-module-fkXA4G-desktopInput:focus::placeholder {
  color: var(--matterColor);
}

.mpc-TopbarSearchForm-module-fkXA4G-desktopInput::placeholder {
  text-overflow: ellipsis;
  font-weight: var(--fontWeightRegular);
  transition: var(--transitionStyleButton);
  overflow-x: hidden;
}

.mpc-TopbarSearchForm-module-fkXA4G-desktopInput {
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .mpc-TopbarSearchForm-module-fkXA4G-desktopInput {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mpc-TopbarSearchForm-module-fkXA4G-mobilePredictions {
  top: calc(var(--TopbarSearchForm_inputHeight)  - var(--TopbarSearchForm_bottomBorder));
  min-height: calc(100vh - calc(var(--TopbarSearchForm_topbarMargin)  + var(--TopbarSearchForm_inputHeight)  - var(--TopbarSearchForm_bottomBorder)));
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup)  + 1);
  width: 100%;
  position: absolute;
  left: 0;
}

.mpc-TopbarSearchForm-module-fkXA4G-mobilePredictionsAttribution {
  margin-bottom: 100px;
}

.mpc-TopbarSearchForm-module-fkXA4G-desktopPredictions {
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup)  + 1);
  width: 100%;
  position: absolute;
  top: 45px;
}

.mpc-TopbarDesktop-module-yv0JrG-root {
  height: var(--topbarHeightDesktop);
  padding: 0 12px;
  padding: 0 var(--find-play-container-padding);
  flex-direction: row;
  align-items: center;
  width: 100%;
  display: flex;
}

.mpc-TopbarDesktop-module-yv0JrG-buttonWrapper {
  height: 42px;
}

.mpc-TopbarDesktop-module-yv0JrG-logoLink {
  transition: var(--transitionStyleButton);
  border-bottom: 0 solid;
}

.mpc-TopbarDesktop-module-yv0JrG-logoLink:hover {
  border-bottom: 4px solid var(--marketplaceColor);
  border-radius: 0;
  text-decoration: none;
}

.mpc-TopbarDesktop-module-yv0JrG-logoLink {
  flex-shrink: 0;
  height: 100%;
  padding: 21px 24px;
}

@media (min-width: 1024px) {
  .mpc-TopbarDesktop-module-yv0JrG-logoLink {
    padding: 21px 36px;
  }
}

.mpc-TopbarDesktop-module-yv0JrG-logo {
  object-fit: contain;
  object-position: left center;
  width: auto;
  max-width: 220px;
  height: 27px;
}

.mpc-TopbarDesktop-module-yv0JrG-searchLink {
  flex-grow: 1;
  align-items: center;
  height: 100%;
  margin-right: 24px;
  display: flex;
}

@media (min-width: 1024px) {
  .mpc-TopbarDesktop-module-yv0JrG-searchLink {
    padding-left: 0;
  }
}

.mpc-TopbarDesktop-module-yv0JrG-search {
  color: currrentColor;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  margin: 24px 0;
  display: inline-block;
}

@media (min-width: 768px) {
  .mpc-TopbarDesktop-module-yv0JrG-search {
    margin: 24px 0;
  }
}

.mpc-TopbarDesktop-module-yv0JrG-search {
  color: var(--matterColor);
}

.mpc-TopbarDesktop-module-yv0JrG-topbarSearchWithLeftPadding {
  background: #fff;
  border-radius: .375rem;
  flex: 1;
  max-width: 640px;
  height: 38px;
}

.mpc-TopbarDesktop-module-yv0JrG-createListingLink {
  color: #000;
  flex-shrink: 0;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 12px;
}

.mpc-TopbarDesktop-module-yv0JrG-createListingLink:hover {
  color: var(--marketplaceColorDark);
}

@media (min-width: 768px) {
  .mpc-TopbarDesktop-module-yv0JrG-createListingLink {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mpc-TopbarDesktop-module-yv0JrG-createListing {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  margin: 24px 0;
  display: inline-block;
}

@media (min-width: 768px) {
  .mpc-TopbarDesktop-module-yv0JrG-createListing {
    margin: 24px 0;
  }
}

.mpc-TopbarDesktop-module-yv0JrG-inboxLink {
  transition: var(--transitionStyleButton);
  border-bottom: 0 solid;
}

.mpc-TopbarDesktop-module-yv0JrG-inboxLink:hover {
  border-bottom: 4px solid var(--marketplaceColor);
  border-radius: 0;
  text-decoration: none;
}

.mpc-TopbarDesktop-module-yv0JrG-inboxLink {
  color: var(--matterColor);
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 12px;
}

.mpc-TopbarDesktop-module-yv0JrG-inboxLink:hover {
  color: var(--matterColorDark);
}

@media (min-width: 768px) {
  .mpc-TopbarDesktop-module-yv0JrG-inboxLink {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mpc-TopbarDesktop-module-yv0JrG-inbox {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  margin: 24px 0;
  display: inline-block;
}

@media (min-width: 768px) {
  .mpc-TopbarDesktop-module-yv0JrG-inbox {
    margin: 24px 0;
  }
}

.mpc-TopbarDesktop-module-yv0JrG-inbox {
  position: relative;
}

.mpc-TopbarDesktop-module-yv0JrG-notificationDot {
  background-color: var(--failColor);
  opacity: 0;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  animation-name: mpc-TopbarDesktop-module-yv0JrG-notificationPop;
  animation-duration: .1s;
  animation-timing-function: ease-in;
  animation-delay: .1s;
  animation-fill-mode: forwards;
  position: absolute;
  top: 3px;
  right: -9px;
  transform: scale(0);
}

@keyframes mpc-TopbarDesktop-module-yv0JrG-notificationPop {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.mpc-TopbarDesktop-module-yv0JrG-profileMenuLabel {
  flex-shrink: 0;
  align-items: center;
  width: auto;
  height: 100%;
  margin: 0;
  display: flex;
}

.mpc-TopbarDesktop-module-yv0JrG-avatar {
  margin: 10px 0;
}

@media (min-width: 768px) {
  .mpc-TopbarDesktop-module-yv0JrG-avatar {
    margin: 16px 0;
  }
}

.mpc-TopbarDesktop-module-yv0JrG-profileMenuContent {
  min-width: 256px;
}

@media (min-width: 768px) {
  .mpc-TopbarDesktop-module-yv0JrG-profileMenuContent {
    max-width: 320px;
  }
}

.mpc-TopbarDesktop-module-yv0JrG-signupLink {
  transition: var(--transitionStyleButton);
  border-bottom: 0 solid;
}

.mpc-TopbarDesktop-module-yv0JrG-signupLink:hover {
  border-bottom: 4px solid var(--marketplaceColor);
  border-radius: 0;
  text-decoration: none;
}

.mpc-TopbarDesktop-module-yv0JrG-signupLink {
  color: var(--matterColor);
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px;
}

.mpc-TopbarDesktop-module-yv0JrG-signupLink:hover {
  color: var(--matterColorDark);
}

.mpc-TopbarDesktop-module-yv0JrG-loginLink {
  transition: var(--transitionStyleButton);
  border-bottom: 0 solid;
}

.mpc-TopbarDesktop-module-yv0JrG-loginLink:hover {
  border-bottom: 4px solid var(--marketplaceColor);
  border-radius: 0;
  text-decoration: none;
}

.mpc-TopbarDesktop-module-yv0JrG-loginLink {
  color: var(--matterColor);
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px;
}

.mpc-TopbarDesktop-module-yv0JrG-loginLink:hover {
  color: var(--matterColorDark);
}

.mpc-TopbarDesktop-module-yv0JrG-signup, .mpc-TopbarDesktop-module-yv0JrG-login {
  color: currrentColor;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  margin: 24px 0;
  display: inline-block;
}

@media (min-width: 768px) {
  .mpc-TopbarDesktop-module-yv0JrG-signup, .mpc-TopbarDesktop-module-yv0JrG-login {
    margin: 24px 0;
  }
}

.mpc-TopbarDesktop-module-yv0JrG-menuItemBorder {
  transition: width var(--transitionStyleButton);
  width: 0;
  height: calc(100% - 4px);
  position: absolute;
  top: 2px;
  left: 0;
}

.mpc-TopbarDesktop-module-yv0JrG-profileSettingsLink, .mpc-TopbarDesktop-module-yv0JrG-yourListingsLink {
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);
  width: 100%;
  margin: 0;
  padding: 4px 24px;
  display: block;
  position: relative;
}

.mpc-TopbarDesktop-module-yv0JrG-profileSettingsLink:hover, .mpc-TopbarDesktop-module-yv0JrG-yourListingsLink:hover {
  color: var(--matterColorDark);
  text-decoration: none;
}

.mpc-TopbarDesktop-module-yv0JrG-profileSettingsLink:hover .mpc-TopbarDesktop-module-yv0JrG-menuItemBorder, .mpc-TopbarDesktop-module-yv0JrG-yourListingsLink:hover .mpc-TopbarDesktop-module-yv0JrG-menuItemBorder {
  background-color: var(--marketplaceColor);
  width: 6px;
}

@media (min-width: 768px) {
  .mpc-TopbarDesktop-module-yv0JrG-profileSettingsLink, .mpc-TopbarDesktop-module-yv0JrG-yourListingsLink {
    margin: 0;
  }
}

.mpc-TopbarDesktop-module-yv0JrG-logoutButton {
  color: var(--marketplaceColor);
  text-decoration: none;
  display: inline;
}

.mpc-TopbarDesktop-module-yv0JrG-logoutButton:hover {
  cursor: pointer;
  text-decoration: underline;
}

.mpc-TopbarDesktop-module-yv0JrG-logoutButton {
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;
  font-size: 14px;
  position: relative;
}

.mpc-TopbarDesktop-module-yv0JrG-logoutButton:hover {
  color: var(--matterColorDark);
  text-decoration: none;
}

.mpc-TopbarDesktop-module-yv0JrG-logoutButton:hover .mpc-TopbarDesktop-module-yv0JrG-menuItemBorder {
  background-color: var(--marketplaceColor);
  width: 6px;
  height: 100%;
  top: 0;
}

@media (min-width: 768px) {
  .mpc-TopbarDesktop-module-yv0JrG-logoutButton {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.mpc-TopbarDesktop-module-yv0JrG-currentPage {
  color: var(--matterColorDark);
  position: relative;
}

.mpc-TopbarDesktop-module-yv0JrG-currentPage .mpc-TopbarDesktop-module-yv0JrG-menuItemBorder {
  background-color: var(--matterColorDark);
  width: 6px;
}

.mpc-TopbarDesktop-module-yv0JrG-currentPage:hover .mpc-TopbarDesktop-module-yv0JrG-menuItemBorder {
  background-color: var(--matterColorDark);
}

.mpc-Topbar-module-Q3J_fq-root {
  z-index: var(--zIndexTopbar);
  width: 100%;
  position: fixed;
}

@media (min-width: 768px) {
  .mpc-Topbar-module-Q3J_fq-root {
    position: relative;
  }
}

.mpc-Topbar-module-Q3J_fq-transparent {
  background: none;
}

.mpc-Topbar-module-Q3J_fq-transparent:before {
  content: "" !important;
  pointer-events: none !important;
  z-index: -1 !important;
  opacity: .4 !important;
  background-image: linear-gradient(#000, rgba(0, 0, 0, 0)) !important;
  width: 100% !important;
  height: 140% !important;
  transition: opacity .15s !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

@media (min-width: 768px) {
  .mpc-Topbar-module-Q3J_fq-fixed {
    width: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

.mpc-Topbar-module-Q3J_fq-absoluteHeaderOffset {
  padding-top: 60px;
}

@media (min-width: 768px) {
  .mpc-Topbar-module-Q3J_fq-absoluteHeaderOffset {
    padding-top: 72px;
  }
}

.mpc-Topbar-module-Q3J_fq-container {
  height: var(--topbarHeight);
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  display: flex;
}

@media (min-width: 768px) {
  .mpc-Topbar-module-Q3J_fq-container {
    display: none;
  }
}

.mpc-Topbar-module-Q3J_fq-home {
  padding: calc((var(--topbarHeight)  - var(--Topbar_logoHeight)) / 2) 24px;
  display: flex;
}

.mpc-Topbar-module-Q3J_fq-logoutButton {
  margin: 0;
}

.mpc-Topbar-module-Q3J_fq-menu, .mpc-Topbar-module-Q3J_fq-searchMenu {
  color: var(--matterColor);
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  margin: 0;
  display: flex;
}

.mpc-Topbar-module-Q3J_fq-menu:enabled, .mpc-Topbar-module-Q3J_fq-searchMenu:enabled {
  cursor: pointer;
}

.mpc-Topbar-module-Q3J_fq-menu:enabled:hover, .mpc-Topbar-module-Q3J_fq-menu:enabled:active, .mpc-Topbar-module-Q3J_fq-searchMenu:enabled:hover, .mpc-Topbar-module-Q3J_fq-searchMenu:enabled:active {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
}

.mpc-Topbar-module-Q3J_fq-menu:disabled, .mpc-Topbar-module-Q3J_fq-searchMenu:disabled {
  cursor: auto;
  background-color: rgba(0, 0, 0, 0);
}

.mpc-Topbar-module-Q3J_fq-menu {
  padding: 15px var(--find-play-container-padding);
  position: relative;
}

.mpc-Topbar-module-Q3J_fq-searchMenu {
  padding: var(--find-play-container-padding);
}

.mpc-Topbar-module-Q3J_fq-menuIcon {
  min-width: 26px;
  min-height: 26px;
}

.mpc-Topbar-module-Q3J_fq-searchMenuIcon {
  margin: 9px 0;
}

.mpc-Topbar-module-Q3J_fq-notificationDot {
  background-color: var(--failColor);
  border: 2px solid rgb(var(--mpc-color-white));
  opacity: 0;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation-name: mpc-Topbar-module-Q3J_fq-notificationPop;
  animation-duration: .1s;
  animation-timing-function: ease-in;
  animation-delay: .1s;
  animation-fill-mode: forwards;
  position: absolute;
  top: 17px;
  right: 17px;
  transform: scale(0);
}

@keyframes mpc-Topbar-module-Q3J_fq-notificationPop {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.mpc-Topbar-module-Q3J_fq-desktop {
  display: none;
}

@media (min-width: 768px) {
  .mpc-Topbar-module-Q3J_fq-desktop {
    display: block;
  }
}

.mpc-Topbar-module-Q3J_fq-rootMenuIcon {
  fill: var(--matterColor);
}

.mpc-Topbar-module-Q3J_fq-rootSearchIcon {
  stroke: var(--matterColor);
}

.mpc-Topbar-module-Q3J_fq-searchContainer {
  height: 100%;
  margin-top: 94px;
  position: relative;
}

.mpc-Topbar-module-Q3J_fq-mobileHelp {
  color: var(--matterColorAnti);
  margin: 0 24px;
  position: absolute;
  top: 73px;
}

.mpc-Topbar-module-Q3J_fq-modalContainer {
  background-color: rgb(var(--mpc-color-white-bg));
  border-radius: var(--borderRadius);
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  padding: 0;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  .mpc-Topbar-module-Q3J_fq-modalContainer {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0;
    flex-grow: 0;
    flex-basis: 480px;
    height: 100%;
    padding: 0;
  }
}

.mpc-Topbar-module-Q3J_fq-modalContainer {
  padding: 0;
}

@media (min-width: 768px) {
  .mpc-Topbar-module-Q3J_fq-modalContainer {
    flex-basis: 576px;
  }
}

.mpc-Topbar-module-Q3J_fq-missingInformationModal {
  padding: var(--modalPadding);
  background-color: rgb(var(--mpc-color-white-bg));
  border-radius: var(--borderRadiusLg);
  border-bottom: none;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  .mpc-Topbar-module-Q3J_fq-missingInformationModal {
    padding: var(--modalPaddingMedium);
    flex-grow: 0;
    flex-basis: 480px;
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
}

.mpc-Topbar-module-Q3J_fq-missingInformationModal {
  padding-top: 70px;
}

.mpc-Topbar-module-Q3J_fq-modalTitle {
  font-weight: var(--fontWeightSemiBold);
  font-weight: var(--fontWeightBold);
  letter-spacing: -.5px;
  margin: 0;
  font-size: 30px;
  line-height: 36px;
}

@media (min-width: 768px) {
  .mpc-Topbar-module-Q3J_fq-modalTitle {
    font-weight: var(--fontWeightSemiBold);
    letter-spacing: -.9px;
    margin: 0;
    line-height: 40px;
  }
}

.mpc-Topbar-module-Q3J_fq-modalMessage {
  margin: 18px 0 0;
}

@media (min-width: 768px) {
  .mpc-Topbar-module-Q3J_fq-modalMessage {
    margin: 24px 0 0;
  }
}

.mpc-Topbar-module-Q3J_fq-email {
  font-weight: var(--fontWeightBold);
}

.mpc-Topbar-module-Q3J_fq-helperLink {
  color: currrentColor;
  margin: 0;
}

@media (min-width: 768px) {
  .mpc-Topbar-module-Q3J_fq-helperLink {
    margin: 0;
  }
}

.mpc-Topbar-module-Q3J_fq-helperText {
  color: currrentColor;
  margin: 0;
}

@media (min-width: 768px) {
  .mpc-Topbar-module-Q3J_fq-helperText {
    margin: 0;
  }
}

.mpc-Topbar-module-Q3J_fq-error {
  color: currrentColor;
  margin-top: 24px;
}

.mpc-Topbar-module-Q3J_fq-bottomWrapper {
  text-align: center;
  align-self: stretch;
  margin-top: 60px;
}

@media (min-width: 768px) {
  .mpc-Topbar-module-Q3J_fq-bottomWrapper {
    margin-top: 84px;
  }
}

.mpc-Topbar-module-Q3J_fq-bottomWrapper {
  text-align: left;
}

.mpc-Topbar-module-Q3J_fq-modalIcon {
  height: 48px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .mpc-Topbar-module-Q3J_fq-modalIcon {
    height: 64px;
    margin-bottom: 23px;
  }
}

.mpc-Topbar-module-Q3J_fq-reminderModalLinkButton {
  background-color: var(--successColor);
  background-color: rgb(var(--mpc-color-accent-600));
  color: rgb(var(--mpc-color-white));
  text-align: center;
  transition: all var(--transitionStyleButton);
  cursor: pointer;
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -.3px;
  border: none;
  border-radius: .375rem;
  width: 100%;
  min-height: 59px;
  margin: 0;
  padding: 20px 0 0;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  display: block;
}

.mpc-Topbar-module-Q3J_fq-reminderModalLinkButton:hover, .mpc-Topbar-module-Q3J_fq-reminderModalLinkButton:focus {
  background-color: var(--marketplaceColorDark);
  box-shadow: var(--boxShadowButton);
  outline: none;
  text-decoration: none;
}

.mpc-Topbar-module-Q3J_fq-reminderModalLinkButton:disabled {
  background-color: var(--matterColorNegative);
  color: rgb(var(--mpc-color-white));
  cursor: not-allowed;
  box-shadow: none;
}

@media (min-width: 768px) {
  .mpc-Topbar-module-Q3J_fq-reminderModalLinkButton {
    min-height: 65px;
  }
}

.mpc-Topbar-module-Q3J_fq-reminderModalLinkButton:hover, .mpc-Topbar-module-Q3J_fq-reminderModalLinkButton:focus {
  background-color: var(--successColorDark);
}

.mpc-Topbar-module-Q3J_fq-reminderModalLinkButton:disabled {
  background-color: var(--matterColorNegative);
  color: rgb(var(--mpc-color-white));
}

.mpc-Topbar-module-Q3J_fq-genericError {
  top: calc(var(--topbarHeight)  + 15px);
  opacity: 0;
  background-color: var(--marketplaceColor);
  z-index: var(--zIndexGenericError);
  border-radius: 4px;
  max-width: 340px;
  margin: 0 15px;
  padding-bottom: 4px;
  transition: all .2s ease-out 1s;
  position: fixed;
  right: -355px;
}

@media (min-width: 768px) {
  .mpc-Topbar-module-Q3J_fq-genericError {
    top: calc(var(--topbarHeightDesktop)  + 15px);
  }
}

.mpc-Topbar-module-Q3J_fq-genericErrorVisible {
  opacity: 1;
  right: 0;
}

.mpc-Topbar-module-Q3J_fq-genericErrorContent {
  background-color: var(--marketplaceColorLight);
  border-radius: 4px;
  padding: 16px 22px;
}

.mpc-Topbar-module-Q3J_fq-genericErrorText {
  color: rgb(var(--mpc-color-white));
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
}

@media (min-width: 768px) {
  .mpc-Topbar-module-Q3J_fq-genericErrorText {
    margin: 0;
  }
}

.mpc-TopbarMarketing-module-f83RSq-transparentHeader:before {
  content: "" !important;
  pointer-events: none !important;
  z-index: -1 !important;
  opacity: .4 !important;
  background-image: linear-gradient(#000, rgba(0, 0, 0, 0)) !important;
  width: 100% !important;
  height: 140% !important;
  transition: opacity .15s !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.mpc-BottomBar-module-63QRfG-root {
  z-index: 10;
  align-items: center;
  height: 125px;
  padding-bottom: 60px;
  display: flex;
  position: fixed;
  bottom: -60px;
  left: 0;
  right: 0;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, .1);
}

@supports (margin-bottom: env(safe-area-inset-bottom)) {
  .mpc-BottomBar-module-63QRfG-root {
    margin-bottom: env(safe-area-inset-bottom) !important;
    padding-left: env(safe-area-inset-left) !important;
    padding-right: env(safe-area-inset-right) !important;
  }
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-sectionContainer {
  margin-bottom: 36px;
  padding: 0;
}

@media (min-width: 768px) {
  .mpc-EditMarketplaceGeneralForm-module-DtTERq-sectionContainer {
    margin-bottom: 56px;
    padding: 0;
  }
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-sectionTitle {
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
}

@media (min-width: 768px) {
  .mpc-EditMarketplaceGeneralForm-module-DtTERq-sectionTitle {
    margin-top: 0;
    margin-bottom: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-lastSection {
  margin-bottom: 69px;
}

@media (min-width: 768px) {
  .mpc-EditMarketplaceGeneralForm-module-DtTERq-lastSection {
    margin-bottom: 111px;
  }

  .mpc-EditMarketplaceGeneralForm-module-DtTERq-lastSection .mpc-EditMarketplaceGeneralForm-module-DtTERq-sectionTitle {
    margin-bottom: 16px;
  }
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-uploadAvatarInput {
  display: none;
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-uploadAvatarWrapper {
  margin-top: 18px;
  margin-bottom: 18px;
}

@media (min-width: 768px) {
  .mpc-EditMarketplaceGeneralForm-module-DtTERq-uploadAvatarWrapper {
    margin-top: 44px;
    margin-bottom: 20px;
  }
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-label {
  width: var(--ProfileSettingsForm_avatarSize);
}

@media (min-width: 768px) {
  .mpc-EditMarketplaceGeneralForm-module-DtTERq-label {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
  }
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-avatarPlaceholder, .mpc-EditMarketplaceGeneralForm-module-DtTERq-avatarContainer {
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);
  background-color: var(--matterColorBright);
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  .mpc-EditMarketplaceGeneralForm-module-DtTERq-avatarPlaceholder, .mpc-EditMarketplaceGeneralForm-module-DtTERq-avatarContainer {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-avatarPlaceholder {
  border-style: dashed;
  border-color: var(--matterColorNegative);
  transition: var(--transitionStyleButton);
  border-width: 2px;
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-avatarPlaceholder:hover {
  border-color: var(--matterColorAnti);
}

@media (min-width: 768px) {
  .mpc-EditMarketplaceGeneralForm-module-DtTERq-avatarPlaceholderTextMobile {
    display: none;
  }
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-avatarPlaceholderText {
  display: none;
}

@media (min-width: 768px) {
  .mpc-EditMarketplaceGeneralForm-module-DtTERq-avatarPlaceholderText {
    text-align: center;
    max-width: 130px;
    display: block;
  }
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-avatarUploadError {
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-error {
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .mpc-EditMarketplaceGeneralForm-module-DtTERq-error {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-avatar {
  width: 100%;
  height: 100%;
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-changeAvatar {
  color: currrentColor;
  font-weight: var(--fontWeightMedium);
  background-color: rgb(var(--mpc-color-white-bg));
  border: solid 1px var(--matterColorNegative);
  background-image: url("data:image/svg+xml;utf8,<svg width=\"14\" height=\"14\" viewBox=\"0 0 14 14\" xmlns=\"http://www.w3.org/2000/svg\"><g stroke=\"%234A4A4A\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9\"/></g></svg>");
  background-position: 15px 12px;
  background-repeat: no-repeat;
  border-radius: 2px;
  width: 105px;
  height: 41px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 10px 10px 11px 35px;
  position: absolute;
  bottom: 27px;
  right: -129px;
}

@media (min-width: 768px) {
  .mpc-EditMarketplaceGeneralForm-module-DtTERq-changeAvatar {
    transition: var(--transitionStyleButton);
    margin-top: 0;
    margin-bottom: 0;
    padding: 7px 10px 11px 35px;
    bottom: -10px;
    right: auto;
  }
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-changeAvatar:hover {
  border: solid 1px var(--matterColorAnti);
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-uploadingImage {
  object-fit: cover;
  background-color: var(--matterColorNegative);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  margin: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-uploadingImageOverlay {
  background-color: rgb(var(--mpc-color-white-bg));
  opacity: .8;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-squareAspectRatio {
  padding-bottom: 100%;
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-tip {
  font-family: var(--fontFamily);
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 5px;
  padding-bottom: 1px;
  font-size: 16px;
}

@media (min-width: 768px) {
  .mpc-EditMarketplaceGeneralForm-module-DtTERq-tip {
    margin-top: 0;
    margin-bottom: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-fileInfo {
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 1px;
}

@media (min-width: 768px) {
  .mpc-EditMarketplaceGeneralForm-module-DtTERq-fileInfo {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-nameContainer {
  justify-content: space-between;
  margin-top: 12px;
  display: flex;
}

@media (min-width: 768px) {
  .mpc-EditMarketplaceGeneralForm-module-DtTERq-nameContainer {
    margin-top: 24px;
  }
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-firstName {
  width: calc(34% - 9px);
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-lastName {
  width: calc(66% - 9px);
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-bioInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;
}

@media (min-width: 768px) {
  .mpc-EditMarketplaceGeneralForm-module-DtTERq-bioInfo {
    margin-top: 16px;
  }
}

.mpc-EditMarketplaceGeneralForm-module-DtTERq-submitButton {
  margin-top: 24px;
}

/*# sourceMappingURL=UiBuilder.f53456cb.css.map */
